import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

type SubmitButtonProps = {
  label: string;
  isLoading: boolean;
  fullWidth: boolean;
  id: string;
  margin?: boolean;
};

const SubmitButton = ({
  label,
  isLoading,
  fullWidth,
  margin = true,
  ...props
}: SubmitButtonProps) => {
  return (
    <>
      {isLoading ? (
        <LoadingButton
          color="secondary"
          loading
          loadingPosition="start"
          startIcon={<SaveIcon />}
          fullWidth={fullWidth}
          variant="contained"
          sx={{ mt: margin ? "5px" : 0 }}
          {...props}
        >
          {label}
        </LoadingButton>
      ) : (
        <Button
          variant="contained"
          color="primary"
          fullWidth={fullWidth}
          type="submit"
          sx={{ mt: margin ? "5px" : 0 }}
          {...props}
        >
          {label}
        </Button>
      )}
    </>
  );
};

export default SubmitButton;
