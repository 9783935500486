import { TextField, styled } from "@mui/material";
import { useField } from "remix-validated-form";

type FormInputProps = {
  name: string;
  placeholder?: string;
  fullWidth?: boolean | false;
  defaultValue?: string;
  disabled?: boolean | false;
  readonly?: boolean | false;
  inputProps?: object;
  formId?: string;
  value?: string;
};
const StyledTextField = styled(TextField)({
  "& input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 1000px #E8F0FE inset", // Set to the desired background color
    WebkitTextFillColor: "#000",
    transition: "background-color 5000s ease-in-out 0s",
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    transition: "color 200ms",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#3E69A6", // Adjust to your focused label color
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(1em, -0.96em) scale(0.75)", // Keep label above when input is filled
  },
});

const FormInput = (FormInputProps) => {
  const {
    name,
    fullWidth,
    placeholder,
    defaultValue,
    disabled,
    inputProps,
    value,
    readonly,
    formId,
  } = FormInputProps;
  const { getInputProps, error } = useField(name, { formId: formId });
  return (
    <StyledTextField
      error={error !== undefined}
      defaultValue={defaultValue}
      helperText={error}
      placeholder={placeholder}
      label={placeholder}
      fullWidth={fullWidth}
      disabled={disabled}
      inputProps={inputProps}
      value={value}
      {...getInputProps({
        id: name,
      })}
    />
  );
};

export default FormInput;
