import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "@remix-run/react";

type BackButtonProps = {
  label: string;
  url: string;
  isLoading?: boolean;
  disabled?: boolean;
};

const BackButton = ({
  label,
  isLoading,
  url,
  disabled,
  ...props
}: BackButtonProps) => {
  return (
    <>
      {isLoading ? (
        <LoadingButton
          color="secondary"
          loading
          loadingPosition="start"
          startIcon={<SaveIcon />}
          fullWidth
          disabled={disabled}
          variant="contained"
          {...props}
        >
          &nbsp;{label}
        </LoadingButton>
      ) : (
        <Link to={url}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            disabled={disabled}
            type="button"
            {...props}
          >
            <ArrowBack fontSize="small" />
            &nbsp;{label}
          </Button>
        </Link>
      )}
    </>
  );
};

export default BackButton;
