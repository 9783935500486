import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useField } from "remix-validated-form";

type FormSelectProps = {
  id: string;
  name: string;
  placeholder: string;
  options: any[];
  defaultValue?: string;
  formId?: string;
  select?: (value: string) => void;
  valueField?: string;
};

const FormSelect = ({
  id,
  name,
  placeholder,
  defaultValue,
  options,
  formId,
  select,
  valueField = "name",
}: FormSelectProps) => {
  const [addressValue, setAddressValue] = useState(
    defaultValue ? defaultValue : ""
  );
  // defaultValue ? select?.(defaultValue) : null;
  const { error, getInputProps } = useField(name, { formId: formId });
  // const { getInputProps, error } = useField(name);
  const handleChange = (event: SelectChangeEvent) => {
    setAddressValue(event.target.value as string);
    select?.(event.target.value as string);
  };

  useEffect(() => {
    if (
      defaultValue === null ||
      !options?.some((e) => e.name === defaultValue)
    ) {
      setAddressValue("");
    }
  }, [options]);

  return (
    <FormControl error={error !== undefined} fullWidth size="small">
      <InputLabel id={id}>{placeholder}</InputLabel>
      <Select
        defaultValue={defaultValue}
        value={addressValue}
        error={error !== undefined}
        id={id}
        label={placeholder}
        onChange={handleChange}
        disabled={typeof options === "undefined"}
        name={name}
      >
        {options?.map((option) => (
          <MenuItem key={option.id} value={option[valueField]}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormSelect;
